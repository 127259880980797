import React, { Component } from 'react';
import styled from 'styled-components';
import CONTENT from '../content';
import main_bottle from '../assets/icons/main_bottle.svg';

const Wrapper = styled.div`
    display:flex;
    align-items:center;
    align-content:center;
    height:100%;
    transition: all 0.4s ease-in-out;
    transform:scale(0.9);
    background:white;
    opacity:0;
    &.loaded {
        transform:scale(1);
        opacity:1;
    }
    &.exiting {
        opacity:0;
        pointer-events:none !important;
    }
    position:absolute;
    top:0;
    left:0;
    z-index:50;
    width:100%;
    height:100%;
`;

const InnerWrapper = styled.div`
    display:flex;
    flex:1;
    padding-top:${61 / 16}rem;
    align-items:center;
    align-content:center;
    flex-direction:column;
`;

const Logo = styled.div`
    flex:1;
    max-width:${40 / 16}rem;
    width:100%;
    height:auto;
    img {
        width:100%;
    }
`;

const QuestionBox = styled.div`
    width:100%;
    max-width:${185 / 16}rem;
    font-size:${20 / 16}rem;
    line-height:${22 / 16}rem;
    margin-top:${20 / 16}rem;
    font-weight:bold;
    text-transform:uppercase;
    text-align:center;
    transition: all 0.4s ease-in-out;
    opacity:0;
    pointer-events:none;
    &.visible {
        opacity:1;
        pointer-events:initial;
    }
`;

const Answers = styled.div`
    display:flex;
    flex:1;
    width:100%;
    max-width:${185 / 16}rem;
    justify-content:space-around;
    transition: all 0.4s ease-in-out;
    opacity:0;
    pointer-events:none;
    &.visible {
        opacity:1;
        pointer-events:initial;
    }
`;

const Answer = styled.div`
    flex:0 0 50%;
    text-align:center;
    text-transform:uppercase;
    font-weight:bold;
    margin-top:${30 / 16}rem;
    color:#A8A8A8;
    cursor:pointer;
    &:hover {
        color:${props => props.mainColor};
    }
`;

const Sections = styled.div`
    opacity:0;
    visibility:hidden;
    pointer-events:none;
    position:absolute;
    height:0;
    overflow:hidden;
`;

class SplashScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded:false,
            loadTimeout:''
        }
    }
    componentDidMount() {
        this.setState({loadTimeout:setTimeout(()=>this.setState({loaded:true}),200)});
    }
    componentWillUnmount() {
        clearTimeout(this.state.loadTimeout);
    }
    render() {
        let { loaded } = this.state;
        let { mainColor, answer, canEnter, sections, splashExit, lang } = this.props;
        return (
            <Wrapper className={`${(loaded ? 'loaded' : '')} ${(splashExit ? 'exiting' : '')}`}>
                <InnerWrapper>
                    <Logo><img alt="РИЖСКIЙ САМОГОНЪ" src={main_bottle} /></Logo>
                    <QuestionBox className={(!canEnter ? 'visible' :'')}>
                        {CONTENT[lang].age_text.question}
                    </QuestionBox>
                    <Answers className={(!canEnter ? 'visible' :'')}>
                        <Answer onClick={answer} mainColor={this.props.mainColor}>{CONTENT[lang].age_text.answers[0]}</Answer>
                        <Answer mainColor={mainColor}>{CONTENT[lang].age_text.answers[1]}</Answer>
                    </Answers>
                    <Sections>{sections}</Sections>
                </InnerWrapper>
            </Wrapper>
        );
    }
}

export default SplashScreen;
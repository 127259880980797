import React from 'react';
import styled, {keyframes} from 'styled-components';
import DistributorMap from './DistributorMap';
import config from '../config';
import axios from 'axios';
import content from '../content';
import ReactHtmlParser from 'react-html-parser';
import { Form, Field } from "react-final-form";
import ReCAPTCHA from "react-google-recaptcha";



let recaptchaInstance;

const resetRecaptcha = () => {
  recaptchaInstance.reset();
};

const FullWrapper = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    height:calc(100% + ${61 / 16}rem);
    background: #fff;
    @media (max-width:1023px) {
        justify-content:flex-start;
        padding-bottom:${80 / 16}rem;
    }

    .image-gallery {
        flex: 1;
        position: relative;
        height:100%;
        width: 100%;
        .image-gallery-content {
            /* position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto; */
            height: 100%;
        }
        .image-gallery-slide-wrapper.bottom {
            height:100%;
        }
        .image-gallery-swipe, .image-gallery-slides, .image-gallery-slide, .image-gallery-image {
            height:100%;
        }
        .image-gallery-image {
            img {
                object-fit: cover;
                min-width: 100%;
                min-height: 100%;
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }
        @media (min-width:1024px) {
            .image-gallery-bullets {
                bottom: 12%;
            }
        }
        @media (min-width:1024px) {
            width:100%;
        }
    }
    .image-gallery-fullscreen-button::before, .image-gallery-play-button::before, .image-gallery-left-nav::before, .image-gallery-right-nav::before {
        text-shadow:0px 0px 0px;
        opacity:0.4;
        transition:opacity 0.2s linear;
    }
    .image-gallery-left-nav:hover::before,
    .image-gallery-right-nav:hover::before {
        color: #fff; 
        opacity:0.6;
    }
    .image-gallery-bullets .image-gallery-bullet {
        background-color: #fff;
        border: none;
        border-radius: 50%;
        box-shadow: 0px 0px 0px;
        cursor: pointer;
        display: inline-block;
        margin: 0 5px;
        outline: none;
        padding: 5px;
        opacity:0.4;
        transition:opacity 0.2s linear;
        &.active {
            background-color: #fff;
            opacity:0.9;
        }
        &:hover {
            background-color: #fff;
            opacity:0.9;
        }
    }
`;

const InnerWrapper = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    flex: 0 1 100%;

    @media (max-width:1023px) {
        flex-direction:column;
        margin-top:${61 / 16 + 29 / 16}rem;
        align-items:flex-start;
    }
`;

const ContentWrapper = styled.div`
    display:flex;
    flex: 0 1 ${454 / 16}rem;
    flex-direction:column;
    margin-left:${120 / 16}rem;
    margin-right:${40 / 16}rem;
    margin-top:-${61 / 16}rem;
    &.contacts {
        text-align:center;
        margin-left:auto;
        margin-right:auto;
        flex:0 0 ${324 / 16}rem;
        .addr {
            margin-bottom:25px;
        }
        a.cn {
            color:${props => props.mainColor};
            text-decoration:none;
            &:hover {
                text-decoration:underline;
            }
        }
        .btn_area {
            display:flex;
            width:100%;
            justify-content:center;
            margin-top:20px;
        }
        a.btn {
            flex: 0 0 40px;
            height:40px;
            display:block;
            border-radius:50%;
            background-color:${props => props.mainColor};
            position:relative;
            transition:transform 0.2s linear;
            svg {
                position:absolute;
                top:0;
                bottom:0;
                left:0;
                right:0;
                margin:auto;
            }
            &:not(last-of-type) {
                margin:10px;
            }
            &:hover {
                transform:scale(1.1);
            }
        }
    }
    background:#fff;
    @media (max-width:1023px) {
        justify-content:flex-start;
        flex-basis: auto;
        min-height:${390 / 16}rem;
        flex-grow:1;
        flex-shrink:0;
        width:calc(100% - ${80 / 16}rem);
        max-width:${506 / 16}rem;
        box-sizing:border-box;
        margin-left:${40 / 16}rem;
        padding-bottom:${40 / 16}rem;
        &.auto_height {
            min-height:initial;
            flex-grow:0;
        }
    }
`;

const SuperTitle = styled.span`
    color:${props => props.mainColor};
    flex:0 0 auto;
    font-size:${16 / 16}rem;
    line-height:${22 / 16}rem;
    font-weight: 500;
`;

const MainTitle = styled.h2`
    font-size:${32 / 16}rem;
    text-transform:uppercase;
    font-weight:900;
    color:${props => props.mainColor};
    flex:1;
    margin-top:0;
    margin-bottom:0;
    .contacts & {
        font-weight:700;
        margin-bottom:25px;
    }
    .map & {
        margin-bottom:20px;
    }
    @media (max-width:1023px) {
        flex:0 0 auto;
    }
`;

const MainText = styled.div`
    font-size:${16 / 16}rem;
    line-height:${22 / 16}rem;
    margin:${20 / 16}rem 0 ${25 / 16}rem 0;
    flex:1;
    p {
        margin-bottom:${25 / 16}rem;
        &:first-of-type {
            margin-top:0;
        }
        &:last-of-type {
            margin-bottom:0;
        }
    }
    @media (max-width:1023px) {
        flex:0 0 auto;
    }
    a {
        color:${props => props.mainColor};
        &:hover {
            color:#f3c269;
        }
    }
    .checks {
        padding:0;
        margin:0;
        list-style:none;
        font-size:${14 / 16}rem;
        li {
            position:relative;
            padding-left:${20 / 16}rem;
            line-height: ${18 / 16}rem;
            &:before {
                content:"";
                width:${14 / 16}rem;
                height:${12 / 16}rem;
                display:block;
                position:absolute;
                background-image:url(${require('../assets/check.svg')});
                background-repeat:no-repeat;
                background-size:contain;
                top: ${4 / 16}rem;
                bottom: initial;
                margin: auto;
                left: 0;
            }
        }
    }
`;

const FootNote = styled.div`
    padding-top:${20 / 16}rem;
    border-top:${1 / 16}rem solid #E5E5E5;
    flex:1;
    font-style: normal;
    font-weight: 600;
    line-height: ${19 / 16}rem;
    font-size: ${14 / 16}rem;
    color:#A8A8A8;
    display:flex;
    @media (max-width:1023px) {
        flex:0 0 auto;
        margin-top:auto;
    }
`;

const FootNoteIcon = styled.div`
    flex: 0 0 ${45 / 16}rem;
    margin-right:${10 / 16}rem;
`;

const FootNoteText = styled.div`
    flex:1;
`;

const SectionVideo = styled.video`
    position: absolute;
    right: -${2 / 16}rem;
    top: 0;
    margin:auto;
    min-width: 100%; 
    min-height: 100%;
    @media (max-width:1023px) {
        top:0;
        bottom:0;
        left:0;
        right:0;
        margin:0 auto;
        min-width: 100%; 
        width:100%;
        min-height: initial;
        position: absolute;
        display:block;
    }
    @media (max-width:540px) {
        height:75vw;
        width:100%;
    }
`;

const SectionVideoWrapper = styled.div`
    height:100%;
    flex:1;
    position:relative;
    overflow:hidden;
    background:${props => props.background ? `url(${props.background}) no-repeat` : 'transparent'};
    background-size:cover;
    background-position:center;
    z-index: 1;
    @media (max-width:1023px) {
        flex:0 1 ${512 / 16}rem;
        height:auto;
        width:100%;
        z-index:0;
    }
    @media (max-width:540px) {
        height:75vw;
        flex: 0 1 75vw;
    }
    .sound {
        position:absolute;
        bottom:${81 / 16}rem;
        right:${20 / 16}rem;
        width:${34 / 16}rem;
        height:${34 / 16}rem;
        transition:all 0.2s linear;
        display:block;
        z-index:1;
        cursor:pointer;
        &:hover {
            transform:scale(1.1);
        }
        &.disabled {
            &:after {
                content:"";
                width:100%;
                height:${2 / 16}rem;
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                margin:auto;
                background:black;
                transform:rotate(-45deg);
            }
        }
    }
`;

const SectionPictureWrapper = styled.div`
    height:100%;
    flex:1;
    position:relative;
    overflow:hidden;
    background:${props => props.background ? `url(${props.background}) no-repeat` : 'transparent'};
    background-size:cover;
    background-position:center;
    z-index: -100;
    @media (max-width:1023px) {
        flex:0 0 70vw;
        height:70vw;
        width:100%;
        z-index:0;
    }
`;

const BackgroundElementWrapper = styled.div`
    height:100%;
    flex:1;
    position:relative;
    overflow:hidden;
    position:relative;
    background:${props => props.bgColor};
    img {
        position: absolute;
        left: 0;
        right: 0;
        top:0;
        bottom: 0;
        display: block;
        height: calc(90% - ${61 / 16}rem);
        width: auto;
        margin: auto;
        margin-top: ${30 / 16}rem;
    }
    @media (max-width:1023px) {
        display:none;
    }
`;

const PriceTag = styled.div`
    font-size: ${12 / 16}rem;
    line-height: ${14 / 16}rem;
    color: #A8A8A8;
    font-weight:600;
    text-transform:uppercase;
    .price {
        font-size: ${20 / 16}rem;
        line-height: ${23 / 16}rem;
        color: #000000;

    }
`;

const PriceDesc = styled.div`
    font-weight: 600;
    font-size: ${14 / 16}rem;
    line-height: ${16 / 16}rem;
    color: #A8A8A8;
    display:block;
    margin-top:${20 / 16}rem;
`;

const ShopButton = styled.button`
    max-width:${400 / 16}rem;
    width:fit-content;
    height:${40 / 16}rem;
    display: flex;
    margin-top:${20 / 16}rem;
    color:#fff;
    outline:0;
    background-color:${props => props.mainColor};
    justify-content:space-between;
    align-items:center;
    transition: all 0.2s linear;
    position:relative;
    z-index:2;
    cursor:pointer;
    border:none;
    padding:0 ${15 / 16}rem;
    font-size:${14 / 16}rem;
    .text {
        flex:0 0 auto;
        font-weight:bold;
        padding-right:${75 / 16}rem;
    }
    .arrow {
        position:relative;
        top:${2 / 16}rem;
        flex:0 0 ${7 / 16}rem;
    }
    &:hover {
        background-color:#f3c269;
    }
    &.purchaseOpen {
        flex: 0 0 auto;
        .text {
            flex:0 0 auto;
            font-weight:bold;
            padding-right:0;
        }
    }
    &:disabled {
        background: #D5D5D5;
        &:hover {
            background-color:#e4e4e4;
        }
    }
`;

const CancelButton = styled.div`
    width:fit-content;
    height:${40 / 16}rem;
    background: #D5D5D5;
    outline:0;
    display: flex;
    margin-top:${20 / 16}rem;
    justify-content:space-between;
    position:relative;
    z-index:2;
    align-items:center;
    transition: all 0.2s linear;
    cursor:pointer;
    border:none;
    padding:0 ${15 / 16}rem;
    color:#fff;
    font-size:${14 / 16}rem;
    flex:0 0 auto;
    font-weight: bold;
    &:hover {
        background-color:#e4e4e4;
    }
`;

const MobileImage = styled.div`
    display:none;
    padding-top:${40 / 16}rem;
    @media (max-width:1023px) {
        display:initial;
    }
    max-width:${415 / 16}rem;
    width:100%;
    img {
        width:100%;
    }
`;


const AwardsContainer = styled.div`
    height:100%;
    flex:1;
    position:relative;
    overflow:hidden;
    position:relative;
    background:#f3f2f8;
    display:flex;
    flex-direction:column;
    justify-content:center;
    @media (max-width:1023px) {
        padding: 40px 0;
    }
`;

const AwardsInner = styled.div`
    flex:0 0 auto;
    width:calc(100% - ${80 / 16}rem);
    display:flex;
    margin-left:${40 / 16}rem;
    margin-right:${40 / 16}rem;
    box-sizing:border-box;
    @media (max-width:640px) {
        flex-wrap:wrap;
    }
`;

const Award = styled.div`
    flex:0 0 calc(33% - ${20 / 16}rem);
    padding-left:${10 / 16}rem;
    padding-right:${10 / 16}rem;
    @media (max-width:640px) {
        flex: 0 0 calc(100% - ${20 / 16}rem);
        margin-bottom:${40 / 16}rem;
    }
    .image {
        width:100%;
        height:${130 / 16}rem;
        position:relative;
        img {
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            margin:auto;
            max-width:80%;
            max-height:80%;
        }
    }
    .type {
        color:${props => props.mainColor};
        font-size:${18 / 16}rem;
        line-height:${25 / 16}rem;
        padding-top:${20 / 16}rem;
        margin-bottom:${14 / 16}rem;
        border-top:1px solid #E5E5E5;
        margin-top:${10 / 16}rem;
        font-weight:600;
    }
`;

const MapType = styled.div`
    font-weight: 600;
    line-height: normal;
    font-size: ${16 / 16}rem;
    color: ${props => props.currentColor};
`;
const MapName = styled.div`
    font-weight: bold;
    line-height: normal;
    font-size: ${24 / 16}rem;
    color: #000000;
    margin:${7 / 16}rem 0;
`;
const MapAddress = styled.div`
    line-height: normal;
    font-size: 16px;
    color: #A8A8A8;
    margin-bottom:${15 / 16}rem;
`;
const Directions = styled.a`
    margin-top:${15 / 16}rem;
    color:#E7BB69;
    font-weight:bold;
    cursor:pointer;
    line-height: normal;
    font-size: ${14 / 16}rem;
    text-decoration:none;
    &:hover {
        text-decoration:underline;
    }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;


const ShopButtonLine = styled.div`
    display:flex;
    width:100%;
    justify-content: space-between;
    align-items: flex-end;
    position:relative;
    z-index:2;
    &:before {
        content:"";
        width:100%;
        height:100%;
        position:absolute;
        top:0;
        left:0;
        background:transparent;
        z-index:1;
    }
    .lds-dual-ring {
        display: inline-block;
        width: ${40 / 16}rem;
        flex: 0 0 ${40 / 16}rem;
        height: ${40 / 16}rem;
        margin-left:auto;
        margin-right:${10 / 16}rem;
        overflow:hidden;
    }
    .lds-dual-ring:after {
        content: " ";
        display: block;
        width: ${28 / 16}rem;
        height: ${28 / 16}rem;
        margin: ${1 / 16}rem;
        border-radius: 50%;
        border: ${5 / 16}rem solid ${props => props.mainColor};
        border-color: ${props => props.mainColor} transparent ${props => props.mainColor} transparent;
        animation: ${rotate} 1.2s linear infinite;
    }

`;

const ShopFormText = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
    position:relative;
    width:100%;
    z-index:2;
    &:before {
        content:"";
        width:100%;
        height:100%;
        position:absolute;
        top:0;
        left:0;
        background:transparent;
        z-index:1;
    }
`;

const QuantityField = styled.div`
    border: ${1 / 16}rem solid #E5E5E5;
    box-sizing: border-box;
    flex: 0 0 100%;
    display:flex;
    margin-top: ${8 / 16}rem;
    height:${40 / 16}rem;
    position:relative;
    z-index:2;
    &:before {
        content:"";
        width:100%;
        height:100%;
        position:absolute;
        top:0;
        left:0;
        background:transparent;
        z-index:1;
    }
    span {
        font-size: ${12 / 16}rem;
        margin-top:${4 / 16}rem;
        color:red;
    }

    .quant_input {
        border:none;
        flex:auto;
        outline:0;
        height:${38 / 16}rem;
        margin-top:0;
    }
    .sum_total {
        flex:0 0 ${85 / 16}rem;
        line-height:${38 / 16}rem;
        font-weight:600;
        text-align:center;
        margin-right:${4 / 16}rem;
        position:relative;
        white-space: nowrap;
        &:before {
            content:"";
            width:${1 / 16}rem;
            height:${20 / 16}rem;
            margin:auto;
            position:absolute;
            left: 0;
            top:0;
            bottom:0;
            background: #C4C4C4;
        }
    }
`;

const FormField = styled.div`
    display:flex;
    align-items:flex-start;
    flex-wrap:wrap;
    position:relative;
    z-index:2;
    &:before {
        content:"";
        width:100%;
        height:100%;
        position:absolute;
        top:0;
        left:0;
        background:transparent;
        z-index:1;
    }
    span {
        font-size: ${12 / 16}rem;
        margin-top:${4 / 16}rem;
        color:red;
    }
    label {
        flex:0 0 100%;
        font-weight: 600;
        font-size: ${12 / 16}rem;
        line-height: ${15 / 16}rem;
        color: #000000;
        margin-top: ${8 / 16}rem;
        position:relative;
        z-index:2;
    }
    input {
        border: ${1 / 16}rem solid #E5E5E5;
        box-sizing: border-box;
        flex: 0 0 100%;
        font-size: ${14 / 16}rem;
        line-height: ${18 / 16}rem;
        padding: ${10 / 16}rem ${10 / 16}rem;
        margin-top: ${6 / 16}rem;
        height:${40 / 16}rem;
        position:relative;
        z-index:2;
    }
    &.half {
        flex: 0 0 50%;
        &.first {
            padding-right:${10 / 16}rem;
        }
        &.second {
            flex: 0 0 calc(50% - ${10 / 16}rem);
        }
    }
`;

const CombinedFormLine = styled.div`
    display:flex;
    align-items:flex-start;
    margin-bottom:${20 / 16}rem;
    &.first {
        padding-top:${10 / 16}rem;
        position:relative;
        z-index:2;
        &:before {
            content:"";
            width:100%;
            height:100%;
            position:absolute;
            top:0;
            left:0;
            background:transparent;
            z-index:1;
        }
    }
`;

const ShopLine = styled.div`
    border: none;
    height:${45 / 16}rem;
    width:100%;
    /* padding: ${25 / 16}rem 0 ${20 / 16}rem 0; */
    position:relative;
    z-index:2;
    &:before {
        content:"";
        width:100%;
        height:100%;
        position:absolute;
        top:0;
        left:0;
        background:transparent;
        z-index:1;
    }
    &:after {
        content:"";
        width:100%;
        height:${1 / 16}rem;
        background: #E5E5E5;
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        margin:auto;
    }
`;

const FormSuccess = styled.div`
    color:${props => props.mainColor};
`;

const AwardsElement = ({awards, mainColor}) => (
    <AwardsContainer>
        <AwardsInner>
            {(awards.map((e,i)=>
                <Award key={i} mainColor={mainColor}>
                    <div className="image"><img alt={'award'} src={e.image} /></div>
                    <div className="type">{e.type}</div>
                    <div className="desc" dangerouslySetInnerHTML={{__html: e.description}} />
                </Award>
            ))}
        </AwardsInner>
    </AwardsContainer>
);

var decodeHTML = function (html) {
	var txt = document.createElement('textarea');
	txt.innerHTML = html;
	return txt.value;
};

const PurchaseFormElement = styled.div`
position:relative;
.overlayer {
        background:rgba(0,0,0,0.5);
        width:100%;
        position:absolute;
        top:0;
        left:0;
        z-index:1;
        height:100%;
        flex:1 1 100%;
    }
    /* &:before {
        content:"";
        background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 6%, rgba(255,255,255,0) 99%, rgba(255,255,255,0) 100%);
        background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 6%,rgba(255,255,255,0) 99%,rgba(255,255,255,0) 100%);
        background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 6%,rgba(255,255,255,0) 99%,rgba(255,255,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
        height:${20 / 16}rem;
        position:absolute;
        width:100%;
        z-index:4;
        top:0;
        left:0;
        pointer-events:none;
    } */
    form {
        overflow-y: scroll;
        background:#fff;
        pointer-events:all;
        position:relative;
        padding-right:${10 / 16}rem;
        top:-0;
        padding-top:0rem;
        ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        }
        ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0,0,0,.5);
            box-shadow: 0 0 1px rgba(255,255,255,.5);
        }
    }
    .form_failure {
        color: red;
        margin-top: ${10 / 16}rem;
        font-weight:bold;
    }
`;

class PurchaseForm extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCaptchaChange = this.onCaptchaChange.bind(this);
        this.state = {
            formCaptcha:false,
            formFailure: false,
            isSending: false
        }
    }
    postRequest = ({ serialized, fields, form }) => {
        //('form vals', serialized, fields, form);
        return {serialized, fields, form}
    }
    onCaptchaChange(value) {
        this.setState({formCaptcha:value});
    }
    onSubmit = (values) => {
        this.setState({isSending:true});
        let formData = new FormData();
        values.captcha = this.state.formCaptcha;
        Object.entries(values).map(([key, value]) => {
            return formData.append(key,value);
        });
        axios.post(config.server+'/get_purchase_request.php', formData)
        .then((res)=>{
            this.setState({isSending:false});
            resetRecaptcha();
            if (res.data === true) {
                this.props.transactionSuccess();
            } else {
                this.setState({formFailure:true});
            }
        })
    }
    render() {
        let { form_text, lang, mobileView, priceAmount } = this.props;
        return (
            <PurchaseFormElement className="purchaseWrapper">
            <Form
            onSubmit={this.onSubmit}
            validate={values => {
                // eslint-disable-next-line
                let re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
              const errors = {};
              if (!values.quantity) {
                errors.quantity = form_text.required_field[lang];
              } else if (isNaN(values.quantity) || values.quantity < 1) {
                errors.quantity = form_text.larger_nr[lang];
              }
              if (!values.company) {
                errors.company = form_text.required_field[lang];
              }
              if (!values.reg_nr) {
                errors.reg_nr = form_text.required_field[lang];
              }
              if (!values.address) {
                errors.address = form_text.required_field[lang];
              }
              if (!values.contact_person) {
                errors.contact_person = form_text.required_field[lang];
              }
              if (!values.email) {
                errors.email = form_text.required_field[lang];
              } else if (!re.test(String(values.email).toLowerCase())) {
                errors.email = form_text.not_valid_email[lang];
              }
              if (!values.phone) {
                errors.phone = form_text.required_field[lang];
              }
              return errors;
            }}

            render={({ handleSubmit, values }) => (
                <form id="purchaseForm" onSubmit={handleSubmit} style={{maxHeight:`${(!mobileView ? this.props.purchaseFormHeight+`px` : 'initial')}`, 'display': `${(this.props.purchaseOpen ? 'block' : 'none')}`}}>
                {/* <div className="overlayer"/> */}
                <Field name="quantity">
                    {({ input, meta }) => (
                      <FormField>
                        <label>{form_text.quantity[lang]}</label>
                        <QuantityField>
                            <input className="quant_input" {...input} type="number" min="1" max="9999" placeholder={form_text.quantity[lang]} />
                            <div className="sum_total">{(values.quantity ? values.quantity * priceAmount : 0).toFixed(2)} eur</div>
                        </QuantityField>
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </FormField>
                    )}
                  </Field>
                  <ShopLine />
                  <ShopFormText>{form_text.fill_form[lang]}</ShopFormText>
                  <CombinedFormLine className="first">
                    <Field name="company">
                        {({ input, meta }) => (
                        <FormField className="half first">
                            <label>{form_text.company[lang]}</label>
                            <input {...input} type="text" placeholder={form_text.company[lang]} />
                            {meta.error && meta.touched && <span>{meta.error}</span>}
                        </FormField>
                        )}
                    </Field>
                    <Field name="reg_nr">
                        {({ input, meta }) => (
                        <FormField className="half second">
                            <label>{form_text.reg_nr[lang]}</label>
                            <input {...input} type="text" placeholder={form_text.reg_nr[lang]} />
                            {meta.error && meta.touched && <span>{meta.error}</span>}
                        </FormField>
                        )}
                    </Field>
                </CombinedFormLine>
                  <Field name="address">
                    {({ input, meta }) => (
                      <FormField>
                        <label>{form_text.addr[lang]}</label>
                        <input {...input} type="text" placeholder={form_text.addr[lang]} />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </FormField>
                    )}
                  </Field>
                  <Field name="contact_person">
                    {({ input, meta }) => (
                      <FormField>
                        <label>{form_text.contact[lang]}</label>
                        <input {...input} type="text" placeholder={form_text.contact[lang]} />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </FormField>
                    )}
                  </Field>
                  <CombinedFormLine>
                    <Field name="email">
                        {({ input, meta }) => (
                            <FormField className="half first">
                                <label>{form_text.email[lang]}</label>
                                <input {...input} type="text" placeholder={form_text.email[lang]} />
                                {meta.error && meta.touched && <span>{meta.error}</span>}
                            </FormField>
                        )}
                        </Field>
                        <Field name="phone">
                        {({ input, meta }) => (
                            <FormField className="half second">
                                <label>{form_text.phone[lang]}</label>
                                <input {...input} type="text" placeholder={form_text.phone[lang]} />
                                {meta.error && meta.touched && <span>{meta.error}</span>}
                            </FormField>
                        )}
                    </Field>
                  </CombinedFormLine>
                  <ReCAPTCHA
                    className={`recaptcha`}
                    sitekey="6LelzKEUAAAAAIi06E0Oz_RMHsVwsRZZnSnPbIH7"
                    onChange={this.onCaptchaChange}
                    ref={e => recaptchaInstance = e}
                    />
                    {(this.state.formFailure && <div className="form_failure">{form_text.formFailed[lang]}</div>)}
                  <ShopButtonLine mainColor={this.props.mainColor}>
                    <CancelButton onClick={()=>this.props.closePurchaseForm()}>{form_text.cancel[lang]}</CancelButton>
                    {(this.state.isSending && <div className="lds-dual-ring"></div>)}
                    <ShopButton disabled={!this.state.formCaptcha || this.state.isSending} type="submit" className={`purchaseOpen`} mainColor={this.props.mainColor} onClick={()=>this.setState({purchaseOpen: true})}>
                        <span className="text">{this.props.shop_button}</span>
                    </ShopButton>
                </ShopButtonLine>
                </form>
              )}
            />
            </PurchaseFormElement>
        )
    }
}

class Section extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShop: (this.props.anchor === 'shop'),
            currentPoint: 0,
            points: [],
            point_types: [],
            pointsLoaded: false,
            priceLoaded: false,
            purchaseOpen: false,
            priceAmount: '',
            purchaseFormHeight: 300,
            formDone:false
        }
        this.changePurchaseFormHeight = this.changePurchaseFormHeight.bind(this);
    }
    componentDidMount() {
        if (this.props.anchor === 'map') {
            axios.get(config.server+'/get_map_points.php').then(e=>{
                const placesArray = e.data.places.filter(p => p && p.lat && parseFloat(p.lat) && p.long && parseFloat(p.long) );
                this.setState({points:placesArray, point_types:e.data.store_types, pointsLoaded:true});
            })
        }
        if (this.props.anchor === 'shop') {
            axios.get(config.server+'/get_price.php').then(e=>{
                const price = parseFloat(e.data.price);
                this.setState({
                    purchaseFormHeight: this.calculatePurchaseFormHeight(),
                    priceAmount: price,
                    priceLoaded: true
                });
                window.addEventListener('resize', this.changePurchaseFormHeight);
            });
        }
        
    }
    calculatePurchaseFormHeight = () => window.innerHeight - document.getElementsByClassName("shop_page_full")[0].getBoundingClientRect().height - 100;
    changePurchaseFormHeight() {
        this.setState({
            purchaseFormHeight: this.calculatePurchaseFormHeight()
        });
    }
    closePurchaseForm = () => this.setState({purchaseOpen:false});
    transactionSuccess = () => this.setState({formDone: true, purchaseOpen:false});
    changePoint = (key) => this.setState({currentPoint: key});
    render() {
        let { 
            isShop,
            points,
            pointsLoaded,
            point_types,
            currentPoint,
            purchaseOpen,
            purchaseFormHeight,
            formDone,
            priceAmount,
            priceLoaded
        } = this.state;
        let { 
            mainColor, 
            superTitle, 
            title, 
            description, 
            footnote, 
            video, 
            background, 
            backgrounds,
            id, 
            anchor, 
            price,
            price_desc,
            shop_button,
            background_element,
            awards,
            sound,
            mobileView,
            lang,
            contact_info,
            langShortId,
            form_text,
            cookies
         } = this.props;
         let showSuperTitle = (anchor !== 'awards' && anchor !== 'map');
         let langPrefix = '';
        if (this.props.lang === 'english') {
            langPrefix = '_en';
        } else if (this.props.lang === 'russian') {
            langPrefix = '_ru';
        }
        let currentPointType = point_types && point_types.length && point_types.find(e=>parseInt(e.type) === parseInt(points[currentPoint].type));
        let currentPointColor = "#F74141";
        if (currentPointType) {
            if (currentPointType.type === 0) currentPointColor = "#F74141";
            else if (currentPointType.type === 1) currentPointColor = "#FF8862";
            else if (currentPointType.type === 2) currentPointColor = "#D8907F";
            else if (currentPointType.type === 3) currentPointColor = "#e7bb69";
            else if (currentPointType.type === 4) currentPointColor = "#D8907F";
            else if (currentPointType.type > 4) currentPointColor = "#D8907F";
        }
        return (
            <div className={`section`} data-anchor={anchor}>
                <FullWrapper mainColor={mainColor}>
                    <InnerWrapper>
                        <ContentWrapper mainColor={mainColor} className={`${(anchor === 'awards' || anchor === 'shop' || anchor === 'map' ? 'auto_height' : '')} ${(anchor === 'shop' ? 'shop_page_full' : '')} ${anchor}`}>
                            {(showSuperTitle && <SuperTitle mainColor={mainColor}>{superTitle}</SuperTitle>)}
                            <MainTitle>{title}</MainTitle>
                            {(price_desc && <PriceDesc>{price_desc}</PriceDesc>)}
                            {(price && priceLoaded && <PriceTag mainColor={mainColor}><span className="price">{priceAmount} EUR</span>{` / ${price[1]}`}</PriceTag>)}
                            {(description && <MainText mainColor={mainColor} dangerouslySetInnerHTML={{__html: description}} />)}
                            {(footnote &&
                                <FootNote>
                                    <FootNoteIcon><img alt={superTitle} src={footnote.icon} /></FootNoteIcon>
                                    <FootNoteText>{footnote.text}</FootNoteText>
                                </FootNote>
                            )}
                            {(isShop &&  
                                <PurchaseForm
                                    purchaseOpen={purchaseOpen}
                                    mainColor={mainColor} 
                                    form_text={form_text}
                                    lang={lang}
                                    price={price}
                                    priceAmount={priceAmount}
                                    priceLoaded={priceLoaded}
                                    shop_button={shop_button} 
                                    closePurchaseForm={this.closePurchaseForm}
                                    transactionSuccess={this.transactionSuccess}
                                    purchaseFormHeight={purchaseFormHeight}
                                    mobileView={mobileView}
                                />
                            )}
                            {(isShop && !purchaseOpen && !formDone &&
                                <ShopButtonLine>
                                    <ShopButton className={`${(purchaseOpen ? 'purchaseOpen' : '')}`} mainColor={mainColor} onClick={()=>{this.setState({purchaseOpen: true})}}>
                                        <span className="text">{shop_button}</span>
                                        <div className="arrow">
                                            <svg width="7" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 0L0 1l5 5-5 5 1 1 6-6-6-6z" fill="#fff"/></svg>
                                        </div>
                                    </ShopButton>
                                </ShopButtonLine>
                            )}
                            {(isShop && formDone && 
                                <FormSuccess mainColor={mainColor}>{form_text.complete[lang]}</FormSuccess>
                            )}
                            {(isShop && background_element && mobileView && <MobileImage isMobile><img alt={title} src={background_element.picture} /></MobileImage>)}
                            {(anchor === 'map' && points.length > 0 &&
                                <div>
                                    <MapType currentColor={currentPointColor}>{currentPointType && currentPointType[`name_${langShortId}`]}</MapType>
                                    <MapName>{points[currentPoint][`name${langPrefix}`]}</MapName>
                                    <MapAddress>{ReactHtmlParser( decodeHTML(points[currentPoint][`address${langPrefix}`]))}</MapAddress> 
                                    <Directions href={`https://maps.google.com/?q=${points[currentPoint].lat},${points[currentPoint].long}`}>{content[lang].map.get_dir}</Directions>
                                </div>
                            )}
                            {(anchor === 'contacts' &&
                                <div className="contact_info_area">
                                    <div className="addr">{ReactHtmlParser(contact_info.address)}</div>
                                    <a className="cn" href={`mailto:${contact_info.email}`}>{contact_info.email}</a>
                                    {(contact_info.phones).map((e,i)=>
                                        <div className="phone" key={i}>{e}</div>
                                    )}
                                    <div className="btn_area">
                                        <a className="btn" href={contact_info.fb}>
                                            <svg width="8" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.193 15.406V8.38h2.358l.354-2.74H5.193V3.892c0-.793.22-1.333 1.358-1.333H8V.107A19.653 19.653 0 0 0 5.888 0C3.796 0 2.365 1.276 2.365 3.62v2.02H0v2.74h2.365v7.026h2.828z" fill="#fff"/></svg>
                                        </a>
                                        <a className="btn" href={contact_info.ig}>
                                        <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.375 0h-6.75A5.626 5.626 0 0 0 0 5.625v6.75A5.626 5.626 0 0 0 5.625 18h6.75A5.626 5.626 0 0 0 18 12.375v-6.75A5.626 5.626 0 0 0 12.375 0zm3.938 12.375a3.942 3.942 0 0 1-3.938 3.938h-6.75a3.942 3.942 0 0 1-3.938-3.938v-6.75a3.942 3.942 0 0 1 3.938-3.938h6.75a3.942 3.942 0 0 1 3.938 3.938v6.75z" fill="#fff"/><path d="M9 4.5a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9zm0 7.313A2.816 2.816 0 0 1 6.187 9 2.816 2.816 0 0 1 9 6.187 2.816 2.816 0 0 1 11.813 9 2.816 2.816 0 0 1 9 11.813zm4.838-7.051a.6.6 0 1 0 0-1.199.6.6 0 0 0 0 1.2z" fill="#fff"/></svg>
                                        </a>
                                    </div>
                                </div>
                            )}
                        </ContentWrapper>
                        {(video &&
                            <SectionVideoWrapper background={(background)}>
                                <SectionVideo id={`video_section_${id}`} muted={!sound || mobileView} playsInline autoPlay loop preload="none">
                                    <source src={video} type="video/mp4"/>
                                </SectionVideo>
                                {(!mobileView && <div className={`sound ${(!sound ? 'disabled' : '')}`} onClick={this.props.toggleSound}>
                                    <svg width="34" height="34" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="17" cy="17" r="17" fill="#fff"/><path d="M20.801 13.985a.819.819 0 0 0-1.15.12.825.825 0 0 0 .138 1.167c.463.378.755.961.755 1.596 0 .687-.326 1.287-.841 1.665a.72.72 0 0 0-.12 1.03l.069.068c.274.326.789.395 1.115.137a3.686 3.686 0 0 0 1.407-2.9 3.7 3.7 0 0 0-1.373-2.883z" fill="#000"/><path d="M22.672 11.686c-.343-.274-.84-.206-1.133.12-.274.343-.24.858.103 1.133 1.133.944 1.871 2.35 1.871 3.93a5.089 5.089 0 0 1-1.87 3.93c-.344.274-.378.789-.104 1.132a.815.815 0 0 0 1.133.137 6.656 6.656 0 0 0 2.488-5.182 6.718 6.718 0 0 0-2.488-5.2zM16.014 10.433l-4.17 3.21H9.802a.96.96 0 0 0-.961.96v4.53a.96.96 0 0 0 .96.962h2.043l4.187 3.209a1.105 1.105 0 0 0 1.785-.893V11.308c0-.91-1.064-1.441-1.802-.875z" fill="#000"/></svg>
                                </div>)}
                            </SectionVideoWrapper>
                        )}
                        {(background &&
                            <SectionPictureWrapper background={background}>
                            </SectionPictureWrapper>
                        )}
                        {(backgrounds &&
                            this.props.imgSlider
                        )}
                        {(background_element &&
                            <BackgroundElementWrapper bgColor={background_element.color}>
                                <img alt={title} src={background_element.picture} />
                            </BackgroundElementWrapper>
                        )}
                        {(anchor === 'awards' && <AwardsElement awards={awards} mainColor={mainColor}/>)}
                        {(anchor === 'map' && 
                            <DistributorMap 
                                points={points} 
                                pointsLoaded={pointsLoaded}
                                pointTypes={point_types} 
                                currentPoint={currentPoint} 
                                changePoint={this.changePoint}
                                cookies={cookies}
                            />)}
                    </InnerWrapper>
                </FullWrapper>
            </div>
        )
    }
}

export default Section;
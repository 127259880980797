import React from 'react';
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import Header from './components/Header';
import styled, { createGlobalStyle } from 'styled-components';
import Section from './components/Section';
import SplashScreen from './components/SplashScreen';
import CONTENT from './content';
import Cookies from 'universal-cookie';
import FirstSection from './components/FirstSection';
import OverlayMenu from './components/OverlayMenu';
import scrollToComponent from 'react-scroll-to-component';
//import Slider from "react-slick";
import ImageGallery from 'react-image-gallery';
import "../node_modules/react-image-gallery/styles/css/image-gallery.css";
// import LastSection from './components/LastSection';
var ls = require('local-storage');

const cookies = new Cookies();

const AppWrapper = styled.div`
    min-height:100%;
    height:100%;
`;

const GlobalStyle = createGlobalStyle`
    body {
        font-family: 'Source Sans Pro';
        margin:0;
        padding:0;
    }
    html, body, #root {
        min-height:100%;
        height:100%;
    }
    .fp-tableCell { vertical-align:top; }
    #fp-nav ul li a span { background:#E5E5E5; }
    #fp-nav ul li a.active span { width:4px; height:4px; margin: -2px 0 0 -2px; background:#000; }

`;

const MainColor = '#E7BB69';

const langs = ['LAT','ENG','RUS'];

const langIds = {LAT:'latvian', ENG:'english', RUS:'russian'};

const langShortIds = {LAT:'lv', ENG:'en', RUS:'ru'};

const FullPageWrapper = styled.div`
    width:100%;
    height:100%;
    box-sizing:border-box;
`;

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: langs[1],
            loaded:false,
            canEnter: false,
            splashExit: false,
            splashTimeout:'',
            splashScreenVisible: true,
            siteLoadStart:'',
            videos:[],
            menuOpen: false,
            sound:false,
            mobileView: (window.innerWidth < 1024)
        }
        this.changeLang = this.changeLang.bind(this);
        this.answerQuestion = this.answerQuestion.bind(this);
        this.loadAssets = this.loadAssets.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.moveToPage = this.moveToPage.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.mobileScrollTo = this.mobileScrollTo.bind(this);
        this.toggleSound = this.toggleSound.bind(this);
    }
    componentDidMount() {
        let userLang = (navigator.language || navigator.userLanguage).substring(0, 2); 
        //console.log(userLang);
        let langToUse;
        if (ls.get('samogon_lang') === null) {
            switch(userLang) {
                case 'lv':
                    langToUse = langs[0];
                    break;
                case 'ru':
                    langToUse = langs[2];
                    break;
                default:
                    langToUse = langs[1];
                    break;
            }
            ls.set('samogon_lang', langToUse);
            this.setState({lang: langToUse});
        } else {
            this.setState({lang: ls.get('samogon_lang')});
        }

        if (cookies.get('is_valid') === 'yes') this.setState({canEnter:true});
        this.loadAssets();
        window.addEventListener('resize', this.handleResize);
    }
    handleResize() {
        if (window.innerWidth < 1024) {
            this.setState({mobileView:true});
            /* window.fullpage_api.destroy();
            console.log('destroy'); */
        }
        else {
            this.setState({mobileView:false});
            /* window.fullpage_api.rebuild(); */
        }
    }
    toggleMenu() {
        this.setState({menuOpen:!this.state.menuOpen});
    }
    moveToPage(nr) {
        window.fullpage_api.moveTo(nr);
    }
    loadAssets() {
        let videoSections = CONTENT.english.sections.filter(e=>e.video);
        let videoPromises = [];
        videoSections.map(e=>{
            //console.log(document.getElementById(`video_section_${e.id}`));
            return videoPromises.push(
                new Promise((resolve,reject)=>{
                    document.getElementById(`video_section_${e.id}`).addEventListener('canplay',()=>{
                        //console.log(`video_section_${e.id} ready`);
                        return resolve(`video_section_${e.id} ready`);
                    })
                })
            )
        })
        this.setState({siteLoadStart:setTimeout(()=>
        Promise.all(videoPromises).then(values=>{
            //console.log(values);
            if (this.state.canEnter)
                    this.setState({loaded:true, splashExit:true, splashTimeout:setTimeout(()=>{
                    //this.setState({splashScreenVisible: false})
                    let allAnchors = CONTENT[langIds[this.state.lang]].sections.map(e=>e.anchor);
                    if (this.state.mobileView && window.location.hash && allAnchors.includes(window.location.hash.substring(1))) {
                        this.mobileScrollTo(window.location.hash.substring(1));
                    }
                    },500)});
                else this.setState({loaded:true});
        }),700)});
    }
    componentWillUnmount() {
        clearTimeout(this.state.splashTimeout);
        clearTimeout(this.state.siteLoadStart);
    }
    toggleSound() {
        this.setState({sound:!this.state.sound})
    }
    mobileScrollTo(e) {
        scrollToComponent(this[e], { offset: -70, align: 'top', duration: 800, ease:'outCirc'});
        // var url = window.location.href;               //Save down the URL without hash.
        window.location.href = "#"+e;                 //Go to the target element.
    }
    answerQuestion = () => {
        if (this.state.loaded)
            this.setState({
                splashExit:true,
                canEnter:true,
                splashTimeout:setTimeout(()=>{
                    // this.setState({splashScreenVisible: false})
                },500)
            })
        else this.setState({canEnter:true});
        cookies.set('is_valid', 'yes', { path: '/' });
    }
    changeLang = (lang) => {
        ls.set('samogon_lang', lang);
        this.setState({lang:lang});
    }
    render() {
        let { lang, loaded, canEnter, splashExit, splashScreenVisible, menuOpen, mobileView, sound} = this.state;
        let sectionsToRender = [];
        let imgSlider = null;
        CONTENT[langIds[lang]].sections.map((e,i)=> {
                if (e.backgrounds) {
                    imgSlider = <ImageGallery 
                        items={e.backgrounds.map(e=>{return({original: e, thumbnail: e})})} 
                        showBullets={true}
                        showThumbnails={false}
                        showPlayButton={false}
                        showFullscreenButton={false}
                        ref={i => this._imageGallery = i}
                    />
                }
                return sectionsToRender.push(<Section
                    key={i}
                    sound={sound}
                    imgSlider={imgSlider}
                    toggleSound={this.toggleSound}
                    ref={(section) => { this[CONTENT.english.sections[i].anchor] = section; }}
                    id={e.id}
                    anchor={CONTENT.english.sections[i].anchor}
                    mainColor={MainColor}
                    superTitle={e.superTitle}
                    title={e.title}
                    description={e.description}
                    footnote={e.footnote}
                    video={e.video}
                    background={e.background}
                    backgrounds={e.backgrounds}
                    price={e.price}
                    price_desc={e.price_desc}
                    shop_button={e.button}
                    background_element={e.background_element}
                    awards={CONTENT[langIds[lang]].awards}
                    mobileView={mobileView}
                    lang={langIds[lang]}
                    langShortId={langShortIds[lang]}
                    contact_info={CONTENT.contact_info}
                    form_text={CONTENT.form_text}
                    cookies={cookies}
                />)
            }
        )
        return (
            <AppWrapper>
                <GlobalStyle/>
                <Header 
                    langId={langIds[lang]} 
                    lang={lang} langs={langs} 
                    changeLang={this.changeLang} 
                    toggleMenu={this.toggleMenu} 
                    menuOpen={menuOpen}
                    mobileView={mobileView}
                    splashScreenVisible={!splashExit}
                    moveToPage={this.moveToPage}
                    mobileScrollTo={this.mobileScrollTo}
                />
                {(splashScreenVisible && <SplashScreen 
                    mainColor={MainColor} 
                    lang={langIds[lang]}
                    answer={this.answerQuestion}
                    canEnter={canEnter}
                    sections={sectionsToRender}
                    splashExit={splashExit}
                />)}
                <OverlayMenu 
                    mainColor={MainColor} 
                    langId={langIds[lang]} 
                    menuOpen={menuOpen} 
                    toggleMenu={this.toggleMenu} 
                    moveToPage={this.moveToPage}
                    mobileScrollTo={this.mobileScrollTo}
                    splashScreenVisible={splashScreenVisible}
                    mobileView={mobileView}
                />
                {(loaded && canEnter && !mobileView && <FullPageWrapper>
                    <ReactFullpage
                        licenseKey="BE51185B-9A0B4382-9D56041B-655E6A12"
                        navigation navigationPosition="left"
                        normalScrollElements={'#purchaseForm'}
                        paddingTop={`${61 / 16}rem`}
                        recordHistory={false}
                        onLeave={(section, origin, destination, direction) => {
                            let vid = origin.item.getElementsByTagName('video');
                            if (vid && vid.length > 0) {
                                vid[0].currentTime = 0;
                                vid[0].play();
                            }

                            if (this._imageGallery) {
                                if (origin.anchor === 'about') {
                                    this._imageGallery.play();
                                }
                                else {
                                    this._imageGallery.pause();
                                }
                            }
                        }}
                        render={({ state, fullpageApi }) => {
                        return (
                            <ReactFullpage.Wrapper>
                                <FirstSection ref={(section) => { this.home = section; }} moveToPage={this.moveToPage}/>
                                {sectionsToRender}
                            </ReactFullpage.Wrapper>
                        );
                        }}
                    />
                </FullPageWrapper>
                )}
                {(loaded && canEnter && mobileView &&
                    <div>
                        <FirstSection ref={(section) => { this.home = section; }} moveToPage={this.moveToPage}/>
                        {sectionsToRender}
                    </div>
                )}
            </AppWrapper>
        )
    }
};

ReactDOM.render(<App />, document.getElementById('root'));

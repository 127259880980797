export default {
    contact_info: {
        address: `Ezermalas iela 13 K4-43A<br>Rīga, Latvija LV-1014`,
        email: 'info@samogon.lv',
        phones: ['+371 67213470', '+371 29457968'],
        fb: 'https://www.facebook.com/SamogonR/',
        ig: 'https://www.instagram.com/samogonr/'
    },
    form_text: {
        complete: {
            latvian: 'Jūsu pieteikums ir saņemts. Mēs ar Jums sazināsimies tuvāko 24 stundu laikā.',
            english: 'Your application has been received. We will contact you within the next 24 hours',
            russian: 'Ваша заявка была получена. Мы свяжемся с вами в течение следующих 24 часов.'
        },
        quantity: {
            latvian: 'Skaits',
            english: 'Quantity',
            russian: 'Количество'
        },
        fill_form: {
            latvian: 'Aizpildiet šo formu, lai veiktu pirkumu',
            english: 'Fill this form to make a purchase',
            russian: 'Заполните форму для покупки'
        },
        company: {
            latvian: 'Kompānija',
            english: 'Company',
            russian: 'Компания'
        },
        reg_nr: {
            latvian: 'Reģistrācijas numurs',
            english: 'Registration Number',
            russian: 'Регистрационный номер'
        },
        addr: {
            latvian: 'Adrese',
            english: 'Address',
            russian: 'Адрес'
        },
        contact: {
            latvian: 'Kontaktpersona',
            english: 'Contact Person',
            russian: 'Контактное лицо'
        },
        email: {
            latvian: 'E-pasts',
            english: 'E-mail',
            russian: 'Электронная почта'
        },
        phone: {
            latvian: 'Tālrunis',
            english: 'Phone',
            russian: 'Телефон'
        },
        cancel: {
            latvian: 'Atcelt',
            english: 'Cancel',
            russian: 'Отменить'
        },
        required_field: {
            latvian: 'Šis lauks jāaizpilda obligāti',
            english: 'This Field is Required',
            russian: 'Это поле обязательно для заполнения'
        },
        not_valid_email: {
            latvian: 'Šī e-pasta adrese nav derīga',
            english: 'This email address is not valid',
            russian: 'Этот адрес электронной почты недействителен'
        },
        larger_nr: {
            latvian: 'Šim laukam jābūt skaitlim, kas ir lielāks par 0',
            english: 'This Field must be a number larger than 0',
            russian: 'Это поле должно быть числом больше 0'
        },
        formFailed: {
            latvian: 'Nosūtot pieprasījumu, radās kļūda. Lūdzu, mēģiniet vēlreiz.',
            english: 'There was an error sending your request. Please try again.',
            russian: 'Произошла ошибка при отправке вашего запроса. Пожалуйста, попробуйте еще раз.'
        }
    },
    english: {
        sections: [
            {   
                id:0,
                superTitle:'About',
                anchor:'about',
                title:'РИЖСКIЙ САМОГОНЪ',
                description:`
                    <p><b>Rizhskiy Samogon РИЖСКIЙ САМОГОНЪ</b> is high quality 43° white honey brandy produced from pure honey in the greenest neighbourhood in Riga - Mezaparks. After fermentation and double distillation in copper pots it is filtrated through Latvian forest moss and activated carbon.</p>
                    <p>High quality 2 litre white honey brandy is a perfect gift for special occasions, weddings and as a valuable souvenir from Latvia.</p>
                    <p><b>The main ingredient for Rizhskiy Samogon distillate is honey.</b></p>
                    <p>Combining the knowledge and experience of architects, designers, engineers, programmers, technologists and chefs with centuries of beekeeping traditions, we have created a contemporary, highly technological craft distillery.</p>
                    <p>Our distillery is highly sustainable and based on circular economy. We use the product fully, with no surplus.</p>
                `,
                backgrounds: [
                    require('./assets/pictures/RS_0_WEB.jpg'),
                    require('./assets/pictures/RS_1_WEB.jpg'),
                    require('./assets/pictures/RS_2_WEB.jpg'),
                    require('./assets/pictures/RS_3_WEB.jpg'),
                    require('./assets/pictures/RS_4_WEB.jpg')
                ]
            },
            {
                id:1,
                superTitle:'Honey',
                anchor:'honey',
                title:'Only Pure Honey',
                description:'Rizhskiy Samogon team has a modern view of the world and a genuine concern for ecology. We have developed a unique recipe that does not use sugar in the creation of Rizhskiy Samogon distillate, only pure sunflower honey.',
                footnote: {
                    icon: require('./assets/icons/honey.svg'),
                    text: '2.7 kg of natural sunflower honey is used to produce 2 litres of Rizhskiy Samogon distillate.'
                },
                video: require('./assets/videos/RS_1.mp4')
            },
            {
                id:2,
                superTitle:'Fermentation',
                anchor:'fermentation',
                title:'Real Fermentation',
                description:'For the creation of the honey distillate only water and yeast are added. The fermentation process takes one week and during that time our technologists are constantly following changes in distillate temperatures and degrees.',
                footnote: {
                    icon: require('./assets/icons/fermentation.svg'),
                    text: 'The distillery equipment developed by our team is particularly energy efficient, thus saving on electricity consumption.'
                },
                video: require('./assets/videos/RS_2.mp4')
            },
            {
                id:3,
                superTitle:'Distillation',
                anchor:'distillation',
                title:'Double Distillation',
                description:'The creation of Rizhskiy Samogon takes place without haste! To provide the highest quality and the softness of the drink it is distilled twice in the copper pots.',
                footnote: {
                    icon: require('./assets/icons/distillation.svg'),
                    text: 'The by-product of the distillation is recycled, so there is no surplus in the production process.'
                },
                video: require('./assets/videos/RS_3.mp4')
            },
            {
                id:4,
                superTitle:'Filtration',
                anchor:'filtration',
                title:'Traditional Filtration',
                description:'The distillate is filtered using activated charcoal and natural moss from Latvian forests. It is precisely the organic filtration process that enhances the quality of the distillate and its properties.',
                footnote: {
                    icon: require('./assets/icons/filtration.svg'),
                    text: 'Approximately 200 two-litre bottles of honey distillate are produced in one shift, therefore the main emphasis is placed on the quality of the product.'
                },
                video: require('./assets/videos/RS_4.mp4')
            },
            {
                id:5,
                superTitle:'Bottling',
                anchor:'bottling',
                title:'Unique 2L Bottles',
                description:'Rizhskiy Samogon is filled in specially designed two-litre bottles. The patented bottle design is minimalistic, ergonomic and iconic.',
                footnote: {
                    icon: require('./assets/icons/bottling.svg'),
                    text: 'The design and development of the bottle took three years from the idea to its realisation.'
                },
                video: require('./assets/videos/RS_5.mp4')
            },
            {
                id:6,
                superTitle:'Packaging',
                anchor:'packaging',
                title:'Ready to be Enjoyed!',
                description:'The honey distillate that we produce is made only from natural honey. It is a great gift for anniversaries, weddings, or as a valuable souvenir from Riga!',
                footnote: {
                    icon: require('./assets/icons/packaging.svg'),
                    text: '43° Rizhskiy Samogon is best enjoyed at room-temperature, experiencing the soft taste of the honey distillate.'
                },
                video: require('./assets/videos/RS_6.mp4')
            },
            {
                id:7,
                superTitle:'Shop',
                anchor:'shop',
                title:'Purchase Request',
                description:`
                    <ul class="checks">
                    <li>Packaged and ready for purchase!</li>
                    <li>Shipping unavailable. Pick-up’s only with invoice (Address - Centrāltirgus street 3 k-4, Riga, Fish Pavilion)</li>
                    <li>Only Companies are eligible for the purchase of alcoholic beverages on the Internet. More info <a href="https://likumi.lv">likumi.lv</a></li>
                    <ul>
                `,
                button: "Make a request",
                price_desc: "1 bottle / 2 litres / 43° alc.vol.",
                price: ['70 EUR', '(inc. VAT)'],
                background_element: {color:'#f3f2f8', picture:require('./assets/bottle_gray.jpg')}
            },
            {
                id:8,
                superTitle:'Awards',
                anchor:'awards',
                title:'Awards',
                description:`Rizhskiy Samogon brand is created with the care for the environment and ecology. We have been awarded with international achievements, both for the taste of the honey distillate as well as for the design and architecture of the distillery.`
            },
            {
                id:9,
                superTitle:'Where to find us?',
                anchor:'map',
                title:'Where to find us?'
            },
            {
                id:10,
                anchor:'contacts',
                title:'Contacts'
            }
        ],
        age_text: {
            question: 'Are you at least 18 years old?',
            answers: ['Yes', 'No']
        },
        awards: [
            {
                type: 'Award',
                description: `Rizhskiy Samogon was awarded with the Gold Medal, as <b>"The Best Taste of the Year"</b> at international food competition in Moscow - <b>PRODEXPO 2018</b>`,
                image: require('./assets/awards/prodexpo.png')
            },
            {
                type: 'Nomination',
                description: `Rizhskiy Samogon brand has been nominated for <b>Latvian Design Awards of the Year 2018</b>`,
                image: require('./assets/awards/dizaina_balva.png')
            },
            {
                type: 'Nomination',
                description: `Rizhskiy Samogon distillery has been nominated for <b>Latvian Architecture Awards of the Year 2018</b>`,
                image: require('./assets/awards/arh_balva.png')
            }
        ],
        map: {
            bar: 'Bar',
            showroom: 'Brand Shop',
            store: 'Store',
            get_dir: 'Get Directions'
        }
    },
    latvian: {
        sections: [
            {   
                id:0,
                superTitle:'Par mums',
                anchor:'about',
                title:'РИЖСКIЙ САМОГОНЪ',
                description:`
                    <p><b>Rižskij Samogon</b> ir augstas kvalitātes 43° kandža, pildīta 2 litru pudelēs un ražota no dabīga medus pašā zaļākajā Rīgas rajonā - Mežaparkā. Tā tiek fermentēta un divkārtēji destilēta kapara alambikos kā arī filtrēta caur meža sūnām un ogli.</p>
                    <p>Augstas kvalitātes dzēriens ir radīts kā ideāla dāvana svētkos, jubilejās un kā jauns suvenīrs Latvijas viesiem.</p>
                    <p><b>Rižskij Samogon destilāta galvenā sastāvdaļa ir tikai un vienīgi medus.</b></p>
                    <p>Apvienojot arhitektu, dizaineru, inženieru, programmētāju, tehnologu, pāvāru zināšanas un pieredzi ar gadsimtu izkoptām biškopju tradīcijām, mēs esam radījuši mūsdienīgu, augstas tehnoloģijas, krafta destilācijas ražotni.</p>
                    <p>Ražotne ir apļveida ekonomikas, slēgta cikla un ekoloģiska, līdz ar to tā izmanto produktu pilnībā, neradot pārpalikumus.</p>
                `,
                backgrounds: [
                    require('./assets/pictures/RS_0_WEB.jpg'),
                    require('./assets/pictures/RS_1_WEB.jpg'),
                    require('./assets/pictures/RS_2_WEB.jpg'),
                    require('./assets/pictures/RS_3_WEB.jpg'),
                    require('./assets/pictures/RS_4_WEB.jpg')
                ]
            },
            {
                id:1,
                superTitle:'Medus',
                anchor:'honey',
                title:'Tikai dabīgs medus',
                description:'Rizhskiy Samogon komandai piemīt mūsdienīgs pasaules redzējums un patiesas rūpes par ekoloģiju. Esam izstrādājuši unikālu recepti, kuras radīšanā Rižskij Samogon destilātā netiek izmantots cukurs, bet gan tikai dabīgs saulespuķu medus.',
                footnote: {
                    icon: require('./assets/icons/honey.svg'),
                    text: 'Lai radītu 2l Rižskij Samogon destilāta, tiek izmantoti 2,7 kg dabīga saulespuķu medus.'
                },
                video: require('./assets/videos/RS_1.mp4')
            },
            {
                id:2,
                superTitle:'Fermentācija',
                anchor:'fermentation',
                title:'ĪSTA FERMENTĀCIJA',
                description:'Medum tiek pievienots tikai ūdens un raugs. Fermentācijas process ilgst vienu nedēļu, un tā laikā mūsu tehnologi nemitīgi seko līdzi destilāta temperatūras un grādu izmaiņām.',
                footnote: {
                    icon: require('./assets/icons/fermentation.svg'),
                    text: 'Mūsu komandas izstrādātās rūpnīcas iekārtas ir īpaši energoefektīvas, tādejādi saudzējot elektrības patēriņu.'
                },
                video: require('./assets/videos/RS_2.mp4')
            },
            {
                id:3,
                superTitle:'Destilācija',
                anchor:'distillation',
                title:'DIVKĀRŠA DESTILĀCIJA',
                description:'Rižskij Samogon radīšana notiek bez steigas! Dzēriens tiek divas reizes destilēts kapara alambikos, lai nodrošinātu augstāko kvalitāti un dzēriena maigo garšu.',
                footnote: {
                    icon: require('./assets/icons/distillation.svg'),
                    text: 'Destilācijas blakusprodukts tiek pārstrādāts, līdz ar to ražošanas procesā nerodas pārpalikumi.'
                },
                video: require('./assets/videos/RS_3.mp4')
            },
            {
                id:4,
                superTitle:'Filtrācija',
                anchor:'filtration',
                title:'Tradicionāla filtrācija',
                description:'Destilāts tiek filtrēts, izmantojot aktivēto ogli un dabīgas Latvijas meža sūnas. Tieši organiskais filtrācijas process uzlabo dzēriena kvalitāti un tā īpašības.',
                footnote: {
                    icon: require('./assets/icons/filtration.svg'),
                    text: 'Vienā maiņā tiek saražotas aptuveni 200 divu litru pudeļu ar medus destilātu, līdz ar to galvenais uzsvars tiek likts uz produkta kvalitāti.'
                },
                video: require('./assets/videos/RS_4.mp4')
            },
            {
                id:5,
                superTitle:'Pudeles',
                anchor:'bottling',
                title:'Unikālas 2L pudeles',
                description:'Rižskij Samogon tiek pildīts īpaši izstrādātās divu litru pudelēs. Patentētais pudeles dizains ir minimālistisks, ergonomisks un ikonisks.',
                footnote: {
                    icon: require('./assets/icons/bottling.svg'),
                    text: 'Pudeles dizains un izstrāde no idejas līdz tās realizācijai aizņēma trīs gadus.'
                },
                video: require('./assets/videos/RS_5.mp4')
            },
            {
                id:6,
                superTitle:'Iepakojums',
                anchor:'packaging',
                title:'Gatavs baudīšanai!',
                description:'Mūsu radītais dzēriens ir gatavots tikai no dabīga medus. Tā ir lieliska dāvana jubilejās, kāzās vai kā vērtīgs suvenīrs no Rīgas!',
                footnote: {
                    icon: require('./assets/icons/packaging.svg'),
                    text: 'Rižskij Samogon 43° stiprais destilāts ir paredzēts nesteidzīgai baudīšanai istabas temperatūrā, izjūtot dzēriena maigo garšu.'
                },
                video: require('./assets/videos/RS_6.mp4')
            },
            {
                id:7,
                superTitle:'Veikals',
                anchor:'shop',
                title:'Pirkuma pierasījums',
                description:`
                    <ul class="checks">
                    <li>Iepakots un gatavs pirkumam!</li>
                    <li>Piegāde nav pieejama. Preci var saņemt veikalā (Adrese - Centrāltirgus iela 3 k-4, Rīga, Zivju Paviljons) ar pavadzīmi.</li>
                    <li>Alkoholisko dzērienu tirdzniecība internetā ir pieejama tikai juridiskām personām. Vairāk informācijas <a href="https://likumi.lv">likumi.lv</a></li>
                    <ul>
                `,
                button: "Pieteikt pirkumu",
                price_desc: "1 pudele / 2 litri / 43° alk.tilp.",
                price: ['70 EUR', '(ar PVN)'],
                background_element: {color:'#f3f2f8', picture:require('./assets/bottle_gray.jpg')}
            },
            {
                id:8,
                superTitle:'Godalgas',
                anchor:'awards',
                title:'Godalgas',
                description:`Rižskij Samogon zīmols tiek radīts ar rūpēm pret dabu un ekoloģiju, līdz ar to mēs esam godalgoti ar starptautiskiem sasniegumiem gan par destilāta garšu, gan par dizainu un arhitektūru.`
            },
            {
                id:9,
                superTitle:'Kur mūs atrast?',
                anchor:'map',
                title:'Kur mūs atrast?'
            },
            {
                id:10,
                anchor:'contacts',
                title:'Kontakti'
            }
        ],
        age_text: {
            question: 'Vai Tev ir vismaz 18 gadu?',
            answers: ['Jā', 'Nē']
        },
        awards: [
            {
                type: 'Godalga',
                description: `Rižskij Samogon ir ieguvis <b>Zelta Medaļu</b>, kā <b>"Gada labākā garša"</b> starptautiskā konkursā Maskavā - <b>PRODEXPO 2018</b>`,
                image: require('./assets/awards/prodexpo.png')
            },
            {
                type: 'Nominācija',
                description: `Rižskij Samogon zīmols ir nominēts - <b>Latvijas Gada balva dizainā 2018</b>`,
                image: require('./assets/awards/dizaina_balva.png')
            },
            {
                type: 'Nominācija',
                description: `Rižskij Samogon ražotne ir nominēta: <b>Latvijas Arhitektūras gada balva 2018</b>`,
                image: require('./assets/awards/arh_balva.png')
            }
        ],
        map: {
            bar: 'Bārs',
            showroom: 'Kompānijas veikals',
            store: 'Veikals',
            get_dir: 'Norādes'
        }
    },
    russian: {
        sections: [
            {   
                id:0,
                superTitle:'О нас',
                anchor:'about',
                title:'РИЖСКIЙ САМОГОНЪ',
                description:`
                    <p><b>Рижский Самогон</b> - 43° дистиллят высокого качества, разлитый в 2л бутылки и производимый из натурального мёда в самом зелёном районе Риги - Межапарке. Он ферментирован и дважды дистиллирован в медных аламбиках, а также фильтрирован через лесной мох - ягель и древесный уголь.</p>
                    <p>Уникальный медовый дистиллят Рижский Самогон является идеальным подарком к юбилею, свадьбе и, без сомнения, весомый сувенир из Риги.</p>
                    <p><b>Главная и единственная составляющая Рижского Самогона - натуральный пчелиный мёд.</b></p>
                    <p>Объединив усилия архитекторов, дизайнеров, инженеров, программистов, технологов, знания поваров и многовековые традиции пчеловодов, мы создали современное, высокотехнологичное, крафтовое производство медового дистиллята.</p>
                    <p>Это производство замкнутого цикла, экологически чистое, безотходное.</p>
                `,
                backgrounds: [
                    require('./assets/pictures/RS_0_WEB.jpg'),
                    require('./assets/pictures/RS_1_WEB.jpg'),
                    require('./assets/pictures/RS_2_WEB.jpg'),
                    require('./assets/pictures/RS_3_WEB.jpg'),
                    require('./assets/pictures/RS_4_WEB.jpg')
                ]
            },
            {
                id:1,
                superTitle:'Мёд',
                anchor:'honey',
                title:'ТОЛЬКО НАТУРАЛЬНЫЙ МЁД',
                description:'Следуя современным мировым тенденциям экологического подхода к к производству, команда Рижского Самогона разработала уникальную технологию получения дистиллята без использования сахара, не создавая отходов, не загрязняя окружающую среду.',
                footnote: {
                    icon: require('./assets/icons/honey.svg'),
                    text: 'Для получения 2л дистиллята Рижский Самогон необходимо 2,7 кг натурального пчелиного мёда.'
                },
                video: require('./assets/videos/RS_1.mp4')
            },
            {
                id:2,
                superTitle:'Ферментация',
                anchor:'fermentation',
                title:'НАТУРАЛЬНАЯ ФЕРМЕНТАЦИЯ',
                description:'В процессе ферментации используется только натуральный мёд и дрожжи. Процесс ферментации длится 7-8 дней под постоянным контролем наших технологов.',
                footnote: {
                    icon: require('./assets/icons/fermentation.svg'),
                    text: 'Разработанное нашей командой технологическое оборудование является энергоэффективным, позволяя многократно использовать выделяемую тепловую энергию.'
                },
                video: require('./assets/videos/RS_2.mp4')
            },
            {
                id:3,
                superTitle:'Дистилляция',
                anchor:'distillation',
                title:'ДВОЙНАЯ ДИСТИЛЛЯЦИЯ',
                description:'Процесс двойной дистилляции происходит размеренно в медных аламбиках, обеспечивая высокое качество и мягкий вкус медового дистиллята.',
                footnote: {
                    icon: require('./assets/icons/distillation.svg'),
                    text: 'Побочный продукт дистилляции полностью перерабатывается.'
                },
                video: require('./assets/videos/RS_3.mp4')
            },
            {
                id:4,
                superTitle:'Фильтрация',
                anchor:'filtration',
                title:'ТРАДИЦИОННАЯ ФИЛЬТРАЦИЯ',
                description:'Готовый дистиллят фильтрируется через древесный уголь и лесной мох ягель. Именно процесс органической фильтрации придает дистилляту особый вкус и аромат.',
                footnote: {
                    icon: require('./assets/icons/filtration.svg'),
                    text: 'В рабочую смену производится около 200 бутылок 2л уникального медового дистиллята.'
                },
                video: require('./assets/videos/RS_4.mp4')
            },
            {
                id:5,
                superTitle:'Бутылки',
                anchor:'bottling',
                title:'УНИКАЛЬНАЯ 2Л БУТЫЛКА',
                description:'Рижский Самогон разливают только в 2л бутылки. Дизайн бутылки - минималистический, эргономический и иконический.',
                footnote: {
                    icon: require('./assets/icons/bottling.svg'),
                    text: 'Разработка, дизайна, патент и изготовление бутылки заняло 3 года.'
                },
                video: require('./assets/videos/RS_5.mp4')
            },
            {
                id:6,
                superTitle:'Упаковка',
                anchor:'packaging',
                title:'РИЖСКИЙ САМОГОН',
                description:'Рождённый нами медовый дистиллят производится только из натурального мёда. Он является замечательным подарком к юбилею, свадьбе и, несомненно, весомым сувениром из Риги.',
                footnote: {
                    icon: require('./assets/icons/packaging.svg'),
                    text: 'Рижский самогон 43° рекомендуем употреблять без спешки, небольшими глотками, наслаждаясь мягким ароматом медового дистиллята.'
                },
                video: require('./assets/videos/RS_6.mp4')
            },
            {
                id:7,
                superTitle:'Магазин',
                anchor:'shop',
                title:'Запрос на покупку',
                description:`
                    <ul class="checks">
                    <li>Упаковано и готово к покупке!</li>
                    <li>Доставка недоступна. Товар можно получить только в Магазине (по адресу - Улица Centrāltirgus 3k-4, Рига, Рыбный павильон) по накладной.</li>
                    <li>Торговля алкогольными напитками в Интернете доступна только юридическим лицам. Более подробная информация: <a href="https://likumi.lv">likumi.lv</a></li>
                    <ul>
                `,
                button: "Подать заявку на покупку",
                price_desc: "1 бутылка / 2 литра / 43°",
                price: ['70 евро', '(с НДС)'],
                background_element: {color:'#f3f2f8', picture:require('./assets/bottle_gray.jpg')}
            },
            {
                id:8,
                superTitle:'Награды',
                anchor:'awards',
                title:'Награды',
                description:`Марка Рижский Самогон создана с заботой об окружающей среде. Рождённый нами продукт отмечен наградами международных конкурсов за вкусовые качества, дизайн и архитектуру.`
            },
            {
                id:9,
                superTitle:'Где нас найти?',
                anchor:'map',
                title:'Где нас найти?'
            },
            {
                id:10,
                anchor:'contacts',
                title:'Контакты'
            }
        ],
        age_text: {
            question: 'Вам уже исполнилось 18 лет?',
            answers: ['Да', 'Нет']
        },
        awards: [
            {
                type: 'Награда',
                description: `Дистиллят Рижский Самогон был награждён <b>Золотой Медалью</b> за <b>Лучший Вкус</b> на 25-й международной выставке <b>ПРОДЭКСПО 2018</b> в Москве.`,
                image: require('./assets/awards/prodexpo.png')
            },
            {
                type: 'Назначение',
                description: `Марка Рижский Самогон номинирована на <b>Приз Года в Латвийском Дизайне 2018.</b>`,
                image: require('./assets/awards/dizaina_balva.png')
            },
            {
                type: 'Назначение',
                description: `Производство Рижский Самогон номинировано на <b>Приз Года Латвийских Архитекторов 2018</b>`,
                image: require('./assets/awards/arh_balva.png')
            }
        ],
        map: {
            bar: 'Бар',
            showroom: 'Фирменный магазин',
            store: 'Магазин',
            get_dir: 'Получить направление'
        }
    }
}
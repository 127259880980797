import React, { Component } from 'react';
import styled from 'styled-components';

const FullWrapper = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    height:calc(100% + ${61 / 16}rem);
    padding-bottom:${61 / 16}rem;
    @media (max-width:1023px) {
        height:50vw;
        margin-top:${61 / 16}rem;
    }
`;

const InnerWrapper = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    flex: 0 1 100%;
    position:relative;
`;

/* const Bottle = styled.img`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    height: 90%;
    width: auto;
    margin: auto;
    @media (max-width:370px) {
        height:auto;
        width:90%;
    }
`; */

const FirstVideo = styled.video`
    min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
    z-index:-1;
    @media (max-width:1024px) {
        width:100%;
        top:28vw;
    }
    /* &.vid { 
        transform:scale(1.15);
        transition:transform 5s linear;
    }
    .fp-viewing-home & {
        &.move {
            transform:scale(1);
        }
    } */
    /* @media (max-width:480px) {
        width: auto;
    height: 100vw;
    left: -50%;
    right: -50%;
    } */
`;

class FirstSection extends Component {

    render() {
        let { mobileView, splashScreenVisible} = this.props;
        return (
            <div className="section fp-auto-height-responsive" data-anchor="home">
                <FullWrapper>
                    <InnerWrapper>
                        {/* <Bottle src={require('../assets/bottle.png')} /> */ }
                        <FirstVideo className={`vid ${(!splashScreenVisible ? 'move' : '')}`} id={`rs_start_video`} muted={true} playsInline autoPlay preload="none"
                            onEnded={()=>{
                                //console.log('home', window.location.hash.substring(1) === 'home', 'hash', !window.location.hash);
                                if (document.body.classList.contains('fp-viewing-home') && (window.location.hash.substring(1) === 'home' || !window.location.hash) && !mobileView) this.props.moveToPage('about');
                            }}
                        >
                            <source src={require('../assets/videos/sam_720.mp4')} type="video/mp4"/>
                        </FirstVideo>
                    </InnerWrapper>
                </FullWrapper>
            </div>
        );
    }
}

export default FirstSection;
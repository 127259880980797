import React from 'react';
import styled from 'styled-components';
const enhanceWithClickOutside = require('react-click-outside');

const hoverColor = `#565656`;

const Container = styled.div`
    flex: 0 0 auto;
    display:flex;
    position:relative;
    cursor:pointer;
    margin-left:auto;
    transition:all 0.3s linear;
    &:after {
        content:"";
        height:${30 / 16}rem;
        width:${1 / 16}rem;
        position:absolute;
        background:transparent;
        right:0;
        top:0;
        bottom:0;
        margin:auto;
        transition:all 0.3s linear;
    }
    margin-right:-${20 / 16}rem;
    &.with-menu {
        padding-right:${20 / 16}rem;
        margin-right:${20 / 16}rem;
        &:after {
            background:#E5E5E5;
        }
    }
`;

const MainBlock = styled.div`
    flex: 0 0 auto;
    display:flex;
    .icon {
        flex: 0 0 ${24 / 16}rem;
        height: ${24 / 16}rem;
        padding-right:${7 / 16}rem;
    }
    .currentLang {
        flex:1;
        color:#000;
        font-weight:bold;
        font-size:${14 / 16}rem;
        line-height:${24 / 16}rem;
        /* padding-right:${5 / 16}rem; */
    }
    .arrow {
        flex: 0 0 ${6 / 16}rem;
        height:${5 / 16}rem;
        position:relative;
        top:-${2 / 16}rem;
    }
    .icon svg path, .arrow svg path, .currentLang { transition:all 0.1s linear; }
    &:hover {
        .icon svg path, .arrow svg path { fill: ${hoverColor} }
        .currentLang { color: ${hoverColor} }
    }
`;

const LangsList = styled.div`
    position:absolute;
    width:100%;
    height:auto;
    top:24px;
    background:#FFF;
    padding: 10px 0px 10px 0px;
`;

const LangItem = styled.div`
    color:#000;
    flex:1;
    font-weight:bold;
    font-size:${14 / 16}rem;
    line-height:${24 / 16}rem;
    margin-left:${31 / 16}rem;
    position: relative;
    z-index:10;
    &:hover {
        color:${hoverColor};
    }
`;

class LanguageSwitcher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownVisible: false
        }
        this.openDropdown = this.openDropdown.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    handleClickOutside() {
        this.closeDropdown();
    }
    openDropdown = () => this.setState({dropdownVisible: true});
    closeDropdown = () => this.setState({dropdownVisible: false});
    render() {
        let { splashScreenVisible } = this.props;
        let { dropdownVisible } = this.state;
        return (
            <Container className={`${(!splashScreenVisible ? 'with-menu' : '')}`} onClick={()=>(!dropdownVisible && this.openDropdown())}>
                <MainBlock>
                    <div className="icon">
                        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 0C5.383 0 0 5.383 0 12s5.384 12 12 12c6.617 0 12-5.383 12-12S18.617 0 12 0zm4.539 21.242c-.709-.577-1.495-.986-2.177-.781-1.529.459-2.79-.598-3.788 1.737a10.193 10.193 0 0 1-2.986-.896c.339-1.317-1.787-2.64-2.384-3.836-.881-1.762 4.659-.698 2.43-8.102-.185-.614-2.607 2.113-4.189 1.409-1.582-.704 1.583-1.762 1.76-3.17.074-.6-.556-1.326-1.301-1.964a10.337 10.337 0 0 1 4.948-3.453c-.066 1.176.174 3.165 2.34 4.008 3.17 1.233 2.818 1.233 2.642 2.994-.176 1.76 1.233 1.571 1.937 2.81.704 1.24-1.292.83-3.464 2.18-2.172 1.35-.762 2.76 1.35 2.23 2.114-.528 3.17-.3 4.756.114 1.164.305 2.333-.275 3.463-1.583a10.345 10.345 0 0 1-5.337 6.303z" fill="#000"/></svg>
                    </div>
                    <div className="currentLang">
                        {this.props.lang}
                    </div>
                    {/* <div className="arrow">
                        <svg width="6" height="5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 5L.402.5h5.196L3 5z" fill="#000"/></svg>
                    </div> */ }
                </MainBlock>
                {(dropdownVisible &&
                    <LangsList>
                        {this.props.langs.map((el,i)=>
                            <LangItem key={i} onClick={()=>{this.props.changeLang(el); this.closeDropdown();}}>{el}</LangItem>
                        )}
                    </LangsList>
                )}
            </Container>
        );
    }
};

export default enhanceWithClickOutside(LanguageSwitcher);
import React, { Component } from 'react';
import styled from 'styled-components';
import CONTENT from '../content';

const MenuWrapper = styled.div`
    width:100%;
    min-height:calc(100% - ${61 / 16}rem);
    height:calc(100% - ${61 / 16}rem);
    background-color:rgba(0,0,0,0.8);
    position:absolute;
    top:${61 / 16}rem;
    left:0;
    z-index:800;
    transition:all 0.4s linear;
    opacity:0;
    pointer-events:none;
    &.active {
        opacity:1;
        pointer-events:initial;
    }
    @media (max-width:1023px) {
        position:fixed;
        overflow-y:scroll;
    }
`;

const Menu = styled.div`
    width:100%;
    height:100%;
    position:relative;
    z-index:10;
`; 

const InnerMenu = styled.ul`
    position:absolute;
    top:${20 / 16}rem;
    right:${30 / 16}rem;
    list-style:none;
    padding:0;
    margin:0;
    z-index:20;
`;

const MenuItem = styled.li`
    font-size:${16 / 16}rem;
    font-weight:600;
    margin-bottom:${18 / 16}rem;
    text-align:right;
    color:#FFF;
    cursor:pointer;
    transition:all 0.1s linear;
    &:hover {
        color:${props => props.mainColor};
    }
`;

class OverlayMenu extends Component {
    constructor(props) {
        super(props);
        this.closeMenu = this.closeMenu.bind(this);
        this.movingFunction = this.movingFunction.bind(this);
    }
    closeMenu(e) {
        if (!e.target.classList.contains('innerMenu')) {
            this.props.toggleMenu();
        }
    }
    movingFunction(e) {
        let {mobileView, moveToPage, toggleMenu, mobileScrollTo} = this.props;
        if (!mobileView) {
            moveToPage(e.anchor); 
        } else {
            mobileScrollTo(e.anchor);
        }
        toggleMenu();
    }
    render() {
        let { mainColor, menuOpen, langId } = this.props;
        return (
            <MenuWrapper className={`${(menuOpen ? 'active' : '')}`}>
                <Menu onClick={this.closeMenu}>
                    <InnerMenu className="innerMenu">
                        {CONTENT[langId].sections.map((e,i)=>
                            <MenuItem onClick={()=>this.movingFunction(e)} key={i} className="innerMenu" mainColor={mainColor}>{(e.superTitle || e.title)}</MenuItem>
                        )}
                    </InnerMenu>
                </Menu>
            </MenuWrapper>
        );
    }
}

export default OverlayMenu;
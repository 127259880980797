import React from 'react';
import styled from 'styled-components';

const Hamburger = styled.div`
    padding: 0;
    display: inline-block;
    cursor: pointer;
    flex:0 0 ${20 / 16}rem;
    transition-property: opacity, filter;
    transition-duration: .15s;
    transition-timing-function: linear;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    /* @media (max-width:660px) {
        padding:14px;
        margin-top:3px;
        margin-left:1px;
    } */
    overflow: hidden;
    outline: 0;
    display:none;
    &:hover {
        opacity: 1;
    }
    opacity:0;
    transform:translateX(80px);
    transition:all 0.3s linear;
    width:0;

    &.visible {
        display:initial;
        opacity:1;
        transform:translateX(0px);
        width:20px;
    }

    .hamburger-box {
        width: 20px;
        height: 14px;
        display: inline-block;
        position: relative;
    }

    .hamburger-inner {
        display: block;
        top: 50%;
        margin-top: -1px;
    }

    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
        width: 20px;
        height: 2px;
        background-color: #000;
        border-radius: 0;
        position: absolute;
        transition-property: transform;
        transition-duration: .15s;
        transition-timing-function: ease;
    }

    .hamburger-inner::after,
    .hamburger-inner::before {
        content: "";
        display: block;
    }

    .hamburger-inner::before {
        top: -6px;
    }

    .hamburger-inner::after {
        bottom: -6px;
    }
        .hamburger-inner {
            transition-duration: 75ms;
            transition-timing-function: cubic-bezier(.55, .055, .675, .19);
            &:before {
                transition: top 75ms .12s ease, opacity 75ms ease;
            }
            &:after {
                transition: bottom 75ms .12s ease, transform 75ms cubic-bezier(.55, .055, .675, .19);
            }
        }
        &.is-active {
            .hamburger-inner {
                transform: rotate(45deg);
                transition-delay: .12s;
                transition-timing-function: cubic-bezier(.215, .61, .355, 1);
                &:before {
                    top: 0;
                    opacity: 0;
                    transition: top 75ms ease, opacity 75ms .12s ease;
                }
                &:after {
                    bottom: 0;
                    transform: rotate(-90deg);
                    transition: bottom 75ms ease, transform 75ms .12s cubic-bezier(.215, .61, .355, 1);
                }
            }
        }
`;


const MenuHamburger = ({ menuOpen, toggleMenu, splashScreenVisible }) => {
    return (
        <Hamburger onClick={toggleMenu} className={`hamburger hamburger--squeeze ${(menuOpen ? 'is-active' : '')} ${(!splashScreenVisible ? 'visible' : '')}`} type="button" aria-label="Menu" aria-controls="navigation">
            <span className="hamburger-box">
                <span className="hamburger-inner"></span>
            </span>
        </Hamburger>
    );
}

export default MenuHamburger;
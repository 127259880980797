import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import controllable from 'react-controllables';

const SectionWrapper = styled.div`
    height:100%;
    flex:1;
    position:relative;
    overflow:hidden;
    z-index:20;
    @media (max-width:1023px) {
        height: 50vw;
        width:100%;
        display:inline-table;
    }
    @media (max-width:840px) {
        height: 70vw;
    }
    @media (max-width:640px) {
        height: 70vw;
    }
    @media (max-width:480px) {
        height: 100vw;
    }
`;

const InnerWrapper = styled.div`
    height:calc(100% - ${61 / 16}rem);
    width:100%;
    @media (max-width:1023px) {
        height: 100%;
        width:100%;
    }
    position:absolute;
    top:0;
    left:0;
`;

/* const LoadingWrapper = styled.div`
    height:calc(100% - ${61 / 16}rem);
    width:100%;
    position:absolute;
    top:0;
    left:0;
`; */

/* const MyMapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={14}
    defaultCenter={{ lat: 56.9441003, lng: 24.1162426 }}
    defaultOptions={{ 
        styles: require('./map_style.json'),
        streetViewControl:false,
        mapTypeControl:false,
        rotateControl:false
    }}
  >
    <Marker 
        position={{ lat: 56.9441003, lng: 24.1162426 }}
        defaultOptions={{ icon: {
            path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
            fillColor: '#F74141',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 0.3
        }}}
    >
    sdfgdf
    </Marker>
  </GoogleMap>
)) */
const Marker = styled.div`
    width:${11 / 16}rem;
    height:${11 / 16}rem;
    position:relative;
    ${props => (props.hover || props.active) &&
    `
    cursor:pointer;
        &:after {
            content:"";
            cursor:pointer;
            position:absolute;
            top:0;
            left:-${4 / 16}rem;
            right:0;
            bottom:0;
            margin:auto;
            background:transparent;
            width:${15 / 16}rem;
            height:${15 / 16}rem;
            border:2px solid black;
            border-radius:50%;
        }
    `
    }
    &:hover {
        svg {
            fill:#000;
        }
    }
`;


const AnyReactComponent = ({ key, type, hover, active }) => {
    let fill = "#F74141";
    type = parseInt(type);
    if (type === 0) fill = "#F74141";
    else if (type === 1) fill = "#FF8862";
    else if (type === 2) fill = "#D8907F";
    else if (type === 3) fill = "#e7bb69";
    else if (type === 4) fill = "#D8907F";
    else if (type > 4) fill = "#D8907F";

    return (
        <Marker hover={hover} active={active}> 
            <svg width="11" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5.5" cy="5.5" r="5.5" fill={fill} /></svg>
        </Marker>
    );
};

//@controllable(['center', 'zoom', 'hoverKey', 'clickKey'])
class DistributorMap extends Component {
    constructor(props) {
        super(props);
        this._onChildClick = this._onChildClick.bind(this);
    }
    _onChildMouseEnter = (hoverKey /*, childProps */) => {
        this.props.onHoverKeyChange(hoverKey);
    }
    _onChildMouseLeave = (/* key, childProps */) => {
        this.props.onHoverKeyChange(null);
    }
    _onChildClick = (hoverKey) => {
        //this.setState({activePoint:key});
        this.props.changePoint(hoverKey);
    }
    componentDidMount() {
        /* axios.get(config.server+'/get_map_points.php').then(e=>{
            this.setState({points:e.data, pointsLoaded:true});
        }) */
    }
    render() {
        let { points, pointsLoaded, currentPoint } = this.props;
        return (
            <SectionWrapper>
                <InnerWrapper>
                    <GoogleMapReact
                        defaultCenter={{ lat: 56.9441003, lng: 24.1162426 }}
                        options={{
                            styles: require('./map_style.json'),
                            streetViewControl:false,
                            mapTypeControl:false,
                            rotateControl:false
                        }}
                        onChildClick={this._onChildClick}
                        onChildMouseEnter={this._onChildMouseEnter}
                        onChildMouseLeave={this._onChildMouseLeave}
                        bootstrapURLKeys={{key: `AIzaSyCWy6vIApQvqH9-UPF2cDGst5BDgNbwPto`}}
                        defaultZoom={14}>
                        {(pointsLoaded &&
                            points.map((e,i)=>
                                <AnyReactComponent
                                    key={i}
                                    lat={e.lat}
                                    lng={e.long}
                                    hover={parseInt(this.props.hoverKey) === i}
                                    active={parseInt(currentPoint) === i}
                                    type={e.type}
                                />
                            )
                        )}
                    </GoogleMapReact>
                </InnerWrapper>
            </SectionWrapper>
        );
    }
}

export default controllable(['center', 'zoom', 'hoverKey', 'clickKey'])(DistributorMap);